<template>
  <div class="list_box">
    <div class="list-nav">
      <page-tab :subNav="subNav" :total="total"></page-tab>
    </div>
    <template v-if="parseInt(total)>0">
      <div class="article">
        <div v-for="(item,index) in listArr" :key="index">
          <!-- 有图文章 -->
          <div class="article-item article-item-img" v-if="item.uploadEntityList.length>0">
            <!-- <a @click="goDetail(item.id)"> -->
              <!-- <router-link :to="`/detail/other/${item.id}?catalogCode=${catalogCode}&columnId=${catalogId}`" target="_blank"> -->
              <router-link :to="`/pages/other-service/other-service-deatil?id=${item.id}&columnId=${catalogId}&catalogCode=${catalogCode}`"  target="_blank">
              <div class="article-item-left">
                <h2>{{item.title}}</h2>
                <div class="item-info" v-if="item.content">{{item.content}}</div>
                <div class="item-footnote">
                  <div class="footnote-scoure" v-if="item.externalSource">来源：{{item.externalSource}}</div>
                  <div class="footnote-timer">{{item.publishDate}}</div>
                </div>
              </div>
              <div class="article-item-img">
                <img v-if="item.uploadEntityList[0] && !item.error" :src="item.uploadEntityList[0].host+item.uploadEntityList[0].url" alt="" @error="item.error = true">
                <img v-else src="@/assets/img/article_list/right.png" alt="">
              </div>
              </router-link>
            <!-- </a> -->
          </div>
          <!-- 无图文章 -->
          <div class="article-item article-item-noimg" v-else>
            <!-- <router-link :to="`/detail/other/${item.id}?catalogCode=${catalogCode}&columnId=${catalogId}`" target="_blank"> -->
            <router-link :to="`/pages/other-service/other-service-deatil?id=${item.id}&columnId=${catalogId}&catalogCode=${catalogCode}`" target="_blank">
            <!-- <a @click="goDetail(item.id)"> -->
              <h2>{{item.title}}</h2>
              <div class="item-info" v-if="item.content">{{item.content}}</div>
              <div class="item-footnote">
                <div class="footnote-scoure" v-if="item.externalSource">来源：{{item.externalSource}}</div>
                <div class="footnote-timer">{{item.publishDate}}</div>
              </div>
            <!-- </a> -->
            </router-link>
          </div>
        </div>
      </div>
      <p class="load-more" v-if="!finished && !loadMore && pageNo>=2" @click="getMore">加载更多</p>
      <p class="load-finish" v-if="finished && pageNo>2">加载完毕</p>
      <p class="loading-text" v-if="loadMore"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
    </template>
    <template v-else>
      <NoData v-if="!loadFlag" /> 
    </template>
  </div>
</template>
<script>
import "@/assets/css/article_list.less";
import { setCatalogTree } from '@/utils/store-data'
import { otherList } from '@/api/list';
import BuryingPont from "@/mixins/buryingPoint.js";
import NoData from '@/components/NoData'
export default {
  components: { NoData },
  mixins: [BuryingPont],
  data() {
    return {
      listArr: [], // 列表数据
      subNav: [], // 面包屑
      total: 0, // 总数
      pageNo: 1,
      pageSize: 6,
      catalogId: this.$route.query.columnId,
      // catalogCode: this.$route.params.id,
      catalogCode: encodeURIComponent(this.$route.query.catalogCode),
      finished: false,
      loadMore: false,
      loading: false,
      loadFlag: false
    };
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollFns);
  },
  created () {
    setCatalogTree(this.$store)
    this.loading = true;
    this.getListData();
    this.loadSecond();
  },
  mounted () {
    window.addEventListener('scroll', this.scrollFns);
  },
  methods: {
    // 列表数据
    getListData(){
      if(!this.loadFlag){
        this.loadFlag = true
        var listData = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          catalogId: this.catalogId,
          catalogCode: this.catalogCode
        }
        otherList(listData).then(res=>{
          this.total = res.count;
          this.subNav = res.data.catalogList;
          res.data.articleInfoEntity.forEach(item => item.error = false)
          if(this.pageNo == 1){
            this.listArr = res.data.articleInfoEntity;
          }else{
            this.listArr = this.listArr.concat(res.data.articleInfoEntity);
          }
          // 是否加载完
          if(this.listArr.length>=this.total || this.pageNo >= res.data.total){
            this.finished = true;
          }
          // 如果只有一个文章，跳转到详情页
          if (this.listArr.length === 1) {
            const item = this.listArr[0]
            if (this.$route.query.name === '新华传播矩阵' || this.$route.query.name === '经济智库' || this.$route.query.name === '政务智库') {
              return this.$router.replace(`/pages/other-service/other-service-deatil?id=${item.id}&name=${this.$route.query.name}&columnId=${this.catalogId}&catalogCode=${this.catalogCode}`)
            }
            return this.$router.replace(`/pages/other-service/other-service-deatil?id=${item.id}&columnId=${this.catalogId}&catalogCode=${this.catalogCode}`)
          }
          // 浏览埋点
          this.sensors.track( 'LY_view_list_page',{
            AppName: this.$store.state.AppName, 
            page_id: '', 
            total_page: res.count % this.pageSize <= 0 ? res.count / this.pageSize : parseInt(res.count / this.pageSize) + 1,
            first_level_column: res.data.catalogList[1].name,
            second_level_column: res.data.catalogList[2].name,
            number: res.count,
            is_login: false, 
            platform: this.$store.state.platform
          })
        }).finally(() => {
          this.loading = false;
          this.loadMore = false;
          this.loadFlag = false;
        })
      }
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond(){
      var that = this;
      var loadData = setInterval(function(){
        if(!this.finished && !that.loadFlag && that.pageNo < 2){
          that.scrollFns();
        }
        if(that.pageNo >= 2 || this.finished){
          clearInterval(loadData)
        }
      },100)
    },
    getMore(){
      this.pageNo++;
      this.loading = true;
      this.loadMore = true;
      this.finished = false;
      this.getListData();
    },
    // 详情页
    // goDetail(id){
    // // window.open(`/detail/other/${id}?catalogCode=${this.catalogCode}`)
    //   this.$router.push({path:'/detail/other/'+id,query:{catalogCode:this.catalogCode}});
    // },
    // 滚动事件
    scrollFns(){
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; 
      if(scrollH-clientH-toTop<322 && !this.loading && !this.finished && this.pageNo<2){
        this.loading = true;
        this.loadMore = true;
        this.finished = false;
        this.pageNo++;
        this.getListData();
      }
    }
  },
  watch: {
    '$route' (to) {
      this.catalogId = to.query.columnId;
      // this.catalogCode = to.params.id;
      this.catalogCode = to.query.catalogCode;
      this.pageNo = 1;
      this.finished = false;
      this.loadMore = false;
      this.loading = false;
      this.listArr = [];
      this.getListData();
      this.loadSecond();
    }
  }
};
</script>