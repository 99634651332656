<template>
  <div :class="{'noData-picture': !picture}" class="NoData">
    <div class="no-list-data" v-if="shwoType == 'list'">
      <p class="no-list-img"></p>
      <p class="no-list-tip">暂无更多内容</p>
    </div>
    <div class="no-person-data" v-else-if="shwoType == 'person'" :style="{'margin-bottom': bottom}">
      <p class="no-rold-img"></p>
      <p class="no-rold-tip">{{text}}</p>
    </div>
    <div class="no-rold-data" v-else :style="{'margin-bottom': bottom}">
      <p class="no-rold-img"></p>
      <p class="no-rold-tip">暂无更多内容</p>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    shwoType: {
      type: String,
      default: 'rold'
    },
    text: {
      type: String,
      default: ""
    },
    bottom: {
      type: String,
      default: '172px',
    },
    picture: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="less">
.NoData{
  .noData-picture{
    .no-list-img,.no-rold-img{
      height: 0px!important;
    }
    .no-rold-data, .no-person-data{
      margin-top: 70px;
    }
  }
  .no-list-data {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: -160px auto 0 auto;
    width: 145px;
    .no-list-img {
      width: 145px;
      height: 83px;
      background: url("~@/assets/img/article_list/no-data.png") no-repeat;
    }
    .no-list-tip {
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
      color: #999999;
    }
  }

  .no-rold-data,
  .no-person-data {
    margin: 144px auto 265px;
    width: 122px;
    .no-rold-img {
      width: 122px;
      height: 96px;
      background: url("~@/assets/img/indexImg/nodata.svg") no-repeat;
      background-size: 100% auto;
      margin: 0 auto;
    }
    .no-rold-tip {
      margin-top: 9px;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .no-person-data {
    margin: 103px auto 172px;
  }
}

</style>
